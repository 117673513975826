import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Enquiry = () => {
  const [enquiryObj, setEnquiryObj] = useState({
    name: "",
    mobile: "",
    email: "",
    program_id: "",
    program_name: "",
    query: "",
    city: "",
    state: "",
    source: "website",
  });

  const [statusObj, setStatusObj] = useState(false);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    async function getprograms() {
      const response = await Axios.post(
        "https://api.manyversity.com/programs/getall"
      );

      if (response.data.status === 1) {
        setPrograms(response.data.data);
      }
    }

    getprograms();
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;

    setEnquiryObj((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });

    if (name === "program_id") {
      const program_selected = programs.find(
        (program) => program._id === value
      );

      setEnquiryObj((preValue) => {
        return {
          ...preValue,
          program_name: program_selected.name,
        };
      });
    }
  };

  const formSubmitted = (event) => {
    event.preventDefault();

    async function postenquiry() {
      try {
        const response = await Axios.post(
          "https://api.manyversity.com/enquiries/insert",
          enquiryObj
        );

        if (response.data.status === 1) {
          setStatusObj(true);
        } else {
          alert("Something went wrong. Please try again.");
        }
      } catch (error) {
        //alert("Something went wrong. Please try again.");
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please check your inputs.",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
          timerProgressBar: true,
        });
      }
    }

    postenquiry();
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <i className="bx bx-home h2"></i>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Online Admission Enquiry
                        </h5>
                        <p>Register your interest or submit your query.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src="assets/images/logo.png"
                          alt=""
                          className="rounded-circle"
                          height="90"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    {statusObj === true && (
                      <div className="text-center">
                        <div className="mb-4">
                          <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                        </div>
                        <h5>Query Submitted</h5>
                        <p className="text-muted">
                          Thank you for showing your interest and submitting
                          your details. We will get in touch with you soon to
                          address your queries.
                        </p>
                      </div>
                    )}
                    {statusObj === false && (
                      <form
                        className="form-horizontal needs-validation"
                        novalidate=""
                        onSubmit={formSubmitted}
                      >
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            name="name"
                            label="Name"
                            className="form-control"
                            placeholder="Enter Name"
                            value={enquiryObj.name}
                            onChange={inputEvent}
                            autoComplete="off"
                            maxLength="40"
                            required
                          />
                          <div class="invalid-feedback">required.</div>
                        </div>

                        <div className="form-group">
                          <label>Mobile</label>
                          <input
                            name="mobile"
                            label="Mobile"
                            type="number"
                            className="form-control"
                            placeholder="Enter Mobile"
                            value={enquiryObj.mobile}
                            onChange={inputEvent}
                            autoComplete="off"
                            minLength="10"
                            maxLength="10"
                            required
                          />
                          <div class="invalid-feedback">required.</div>
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            value={enquiryObj.email}
                            onChange={inputEvent}
                            autoComplete="off"
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>State</label>
                          <input
                            name="state"
                            label="State"
                            className="form-control"
                            placeholder="Enter State"
                            type="text"
                            value={enquiryObj.state}
                            onChange={inputEvent}
                            autoComplete="off"
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            name="city"
                            label="City"
                            className="form-control"
                            placeholder="Enter City"
                            type="text"
                            value={enquiryObj.city}
                            onChange={inputEvent}
                            autoComplete="off"
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>Program</label>
                          <select
                            name="program_id"
                            className="form-control"
                            onChange={inputEvent}
                            required
                          >
                            <option value="" label="--Select Program--" />
                            {programs ? (
                              programs.map((item) => (
                                <option
                                  key={item._id}
                                  value={item._id}
                                  label={item.name}
                                />
                              ))
                            ) : (
                              <option value=""></option>
                            )}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Query</label>
                          <textarea
                            name="query"
                            label="Query"
                            rows="4"
                            className="form-control"
                            required
                            placeholder="Write your query"
                            value={enquiryObj.query}
                            onChange={inputEvent}
                            maxLength="100"
                          ></textarea>
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Submit Query
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} manyversity.com
                  <br />
                  Powered by{" "}
                  <a target="_blank" href="https://onebigbit.com">
                    {" "}
                    OneBigBit Technologies Private Limited.
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Enquiry;
