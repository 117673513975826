import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

function VerifyEmail(props) {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    async function getdata() {
      const id = props.match.params.id;

      const response = await Axios.post(
        "https://api.manyversity.com/admissionapplicants/verifyemail",
        { id }
        //{ id: "5ef44901e0029e17a08d44ca" }
      );

      if (response.data.status === 1) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    }

    getdata();
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {/* <Link to="/" className="text-dark"> */}
        <i className="bx bx-home h2"></i>
        {/* </Link> */}
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-8">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Online Admission Application
                        </h5>
                        <p>Email Verification</p>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {/* <Link to="/"> */}
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src="assets/images/logo.png"
                          alt=""
                          className="rounded-circle"
                          height="90"
                        />
                      </span>
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="p-2">
                    {verified === true && (
                      <div className="text-center">
                        <div className="mb-4">
                          <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                        </div>
                        <h5>Email Verified</h5>
                        <p className="text-muted">
                          Thank you for verifying your email address. Please
                          proceed with your application submission.
                        </p>
                      </div>
                    )}
                    {verified === false && (
                      <div className="text-center">
                        <div className="mb-4">
                          <i className="mdi mdi-alert-circle-outline text-danger display-4"></i>
                        </div>
                        <h5>Something went wrong.</h5>
                        <p className="text-muted">
                          This link appears to be invalid, contact at{" "}
                          <a
                            href="mailto:help@manyversity.com"
                            className="text-primary"
                          >
                            help@manyversity.com
                          </a>{" "}
                          for help.
                        </p>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} manyversity.com
                  <br />
                  Powered by{" "}
                  <a target="_blank" href="https://onebigbit.com">
                    {" "}
                    OneBigBit Technologies Private Limited.
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default VerifyEmail;
