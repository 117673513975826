import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import Enquiry from "./Enquiry";
import Error from "./Error";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/enquiry" component={Enquiry} />
        <Route exact path="/verifyemail/:id" component={VerifyEmail} />
        <Route component={Error} />
      </Switch>
    </>
  );
}

export default App;
